header {
  position: fixed;
  top: 0.8em;
  left: 1em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: calc(100vw - 2em);
  height: fit-content;
  padding: 0;
  z-index: 24;
}

.navigation a {
  margin-left: 1em;
  cursor: e-resize;
}

.navigation a.active {
  color: var(--grey);
  cursor: default;
}

.name:hover {
  color: var(--black);
}

@media only screen and (max-width: 1024px) {
  header {
    background: var(--white);
    top: 0;
    padding: 0.8em 0 0;
  }
}
