footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: calc(100vw - 2em);
  position: fixed;
  bottom: 0.8em;
  left: 1em;
  padding: 0;
  z-index: 24;
}

footer a {
  margin-left: 1.2em;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #aaa;
}

@media only screen and (max-width: 1024px) {
  footer {
    background: var(--white);
    bottom: 0;
    padding: 0.8em 0;
    align-items: flex-end;
  }
}
