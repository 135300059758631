section {
  padding: 20em 1em 1em 32em;
  justify-content: flex-start;
}

@media only screen and (max-width: 1024px) {
  section {
    padding: 22em 1em 16em;
    display: flex;
  }

  br {
    display: none;
  }
}
