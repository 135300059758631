@font-face {
  font-family: "ApfelGrotezk";
  src: url("../fonts/ApfelGrotezk-Regular.woff2") format("woff2"),
    url("../fonts/ApfelGrotezk-Regular.woff") format("woff");
}

@font-face {
  font-family: "Necto-Mono";
  src: url("../fonts/Necto-Mono.woff2") format("woff2"),
    url("../fonts/Necto-Mono.woff") format("woff");
}

:root {
  --black: #36293f;
  --white: #fcfcfc;
  --pink: #ffebf8;
  --grey: #aaa;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "ApfelGrotezk";
  color: var(--black);
  background-color: var(--white);
  -webkit-font-smoothing: antialiased;
}

*::selection {
  background: var(--pink);
}

h1 {
  font-family: "Necto-Mono";
  text-indent: 5em;
  font-size: 1em;
  line-height: 1;
  margin: 0;
  text-decoration: none;
  font-weight: 400;
  width: 60%;
}

h2 {
  font-size: 2em;
  line-height: 1em;
  margin: 0;
  text-decoration: none;
  font-weight: 400;
}

h3 {
  font-size: 1.25em;
  line-height: 1em;
  margin: 0;
  text-decoration: none;
  font-weight: 400;
}

main {
  padding: 20em 1em 10em 32em;
}

div,
a,
p {
  font-size: 1em;
  line-height: 1.2em;
  margin: 0;
  text-decoration: none;
  color: var(--black);
  transition: all 0.3s ease-in-out;
}

.mono,
h1 a {
  font-family: "Necto-Mono";
  font-size: 1em;
  letter-spacing: -0.05em;
  font-weight: 300;
}

h1 a {
  padding-bottom: 0.2em;
  border-bottom: 1px solid var(--pink);
}

.grey {
  color: var(--grey);
}

a:hover {
  color: var(--grey);
}

@media only screen and (max-width: 1600px) {
  main {
    padding: 10em 1em 8em 16em;
  }
}

@media only screen and (max-width: 1440px) {
  main {
    padding: 10em 1em 8em 10em;
  }
}

@media only screen and (max-width: 1280px) {
  main {
    padding: 1em;
  }

  h1 {
    text-indent: 4em;
    width: 80%;
  }

  h2 {
    font-size: 1.5em;
  }
}
